import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./features/users/userSlice";
import contentReducer from "./features/workspaceContent/contentSlice";
export const store = 
  configureStore({
    reducer: {
      user: userReducer,
      content: contentReducer,
    }
  });
