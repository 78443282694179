// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import "firebase/compat/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAmfycVzM8GlNCkWbNrQv617Hb_kM6ZKMo",
  authDomain: "edjobster-ai.firebaseapp.com",
  projectId: "edjobster-ai",
  storageBucket: "edjobster-ai.appspot.com",
  messagingSenderId: "176737641206",
  appId: "1:176737641206:web:3c93568aa1f8570afa42d7",
  measurementId: "G-VX4TLP6T8M"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
//to initialize the firebase authentication and get a reference to the it
const auth = firebase.auth();
//below is the provider for the google authentication services
const provider = new firebase.auth.GoogleAuthProvider();
const analytics = firebase.analytics();
export { auth, provider, analytics };