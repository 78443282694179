import React from "react";
import images from "../constants/images";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";

const Banner = () => {
  function GetStarted(){
    if(localStorage.getItem("token")  === null)
    window.location.href = "/signup";
    else
    window.location.href = "/workspace";
  }
  return (
    <div
      id="contact"
      className="bg-[#181846] h-[700px] w-full flex flex-col md:flex-row items-center justify-center space-y-0 md:space-y-0"
    >
      <div className="max-w-[500px] md:mr-10 mb-28 px-5 md:px-0 ">
        <h1 className=" text-2xl md:text-6xl text-green-500 font-medium mb-4">
          Try It Out For Free
        </h1>
        <p className="text-white max-w-lg md:max-w-xl text-sm md:text-md">
          We provide teachers with a range of tools that help them be more
          creative, develop their skills, and plan lessons that suit their
          students. Our platform also makes it easier to find and access
          educational resources in a new and efficient way.
        </p>
        
          <a onClick={GetStarted} className=" block text-white font-bold w-[200px] cursor-pointer bg-green-500 hover:bg-green-600 mt-6 text-center py-2 px-6 ">
            Get Started
            <FaArrowRight className="inline-block ml-2" />
          </a>
      </div>
      <div className="h-fit w-full md:w-[800px] mt-20 px-5 md:px-0">
        <img src={images.test1} className="m-auto" alt="header_img" />
      </div>
    </div>
  );
};

export default Banner;
