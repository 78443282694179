import React, { useEffect, useState, useRef } from "react";
import WorkSpaceCards from "./WorkSpaceCards";
import {
  CLASS_ANNOUNCEMENT_URL,
  EMAIL_TEMPLATE_URL,
  LESSON_PLAN_URL,
  SAVE_CONTENT_URL,
  UNIT_PLAN_URL,
  UPDATE_CONTENT_URL,
} from "../constants/urls";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectSavedContent } from "../features/workspaceContent/contentSlice";
import { Alert, Snackbar } from "@mui/material";
import { Oval } from "react-loader-spinner";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { analytics, auth } from "../firebase";
import { setUserLogOut } from "../features/users/userSlice";

const WorkSpace = () => {
  const [topicName, setTopicName] = useState("");
  const [curr, setCurr] = useState("");
  const [result, setResult] = useState("");
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const editorRef = useRef();
  const [msg,setmsg]=useState("")
  const [showmsg,setshowmsg]=useState(false)

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const savedContent = useSelector(selectSavedContent);
  const { id } = useParams();

  function handleClicked(title) {
    if (curr.length > 0) document.getElementById(curr).style.border = "none";
    document.getElementById(title).style.border = "1.5px solid #FFB800";
    setCurr(title);
  }

  useEffect(() => {
    if (id) {
      setTopicName(savedContent.topic);
      setResult(savedContent.content);
      document.getElementById("grade").value = savedContent.grade_level;
    }
  }, []);

  const handleEditorChange = (value) => {
    setResult(value);
    //console.log(value);
  };

  const signOut = () => {
    auth.signOut().then(() => {
      dispatch(setUserLogOut());
      navigate("/signup");
    });
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    localStorage.removeItem("email");
    localStorage.removeItem("photo");
  };

  function generateWorkspaceContent() {
    setshowmsg(true)
    let token = localStorage.getItem("token");
    let selectedGrade = document.getElementById("grade").value;
    if (
      selectedGrade == undefined ||
      selectedGrade == "" ||
      token == undefined ||
      topicName == ""
    ) {
      setError(true);
      setErrorMessage("Please fill all the fields");
      return;
    }
    setLoader(true);
    setmsg("Generating your content")
    var myHeaders = new Headers();
    myHeaders.append("x-access-token", token);
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      topic: topicName,
      grade_level: selectedGrade,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    switch (curr) {
      case "Lesson Plan":
        fetch(LESSON_PLAN_URL, requestOptions)
          .then(async (response) => {
            const res = await response.text();
            if (!response.ok) throw res;
            return res;
          })
          .then((res) => {
            res = res.replace(/\n/g, "<br />");
            setResult(result + res);
            setLoader(false);
            analytics.logEvent("workspace", {
              type: curr,
              topic: topicName,
              grade_level: selectedGrade
            });
          })
          .catch((error) => {
            setLoader(false);
            error = JSON.parse(error);
            if (error.error === "token has expired") {
              setError(true);
              setErrorMessage("Session expired. Please login again");
              setTimeout(() => {
                signOut();
              }, 2000);
              return;
            }
            setErrorMessage("Something went wrong");
            setError(true);
          });
        break;
      case "Unit Plan":
        fetch(UNIT_PLAN_URL, requestOptions)
          .then(async (response) => {
            const res = await response.text();
            if (!response.ok) throw res;
            return res;
          })
          .then((res) => {
            res = res.replace(/\n/g, "<br />");
            setResult(result + res);
            setLoader(false);
            setmsg("");
            analytics.logEvent("workspace", {
              type: curr,
              topic: topicName,
              grade_level: selectedGrade
            });
          })
          .catch((error) => {
            setLoader(false);
            setmsg("")
            error = JSON.parse(error);
            if (error.error === "token has expired") {
              setError(true);
              setErrorMessage("Session expired. Please login again");
              setTimeout(() => {
                signOut();
              }, 2000);
              return;
            }
            setErrorMessage("Something went wrong");
            setError(true);
          });
        break;

      case "Class Announcement":
        fetch(CLASS_ANNOUNCEMENT_URL, requestOptions)
          .then(async (response) => {
            const res = await response.text();
            if (!response.ok) throw res;
            return res;
          })
          .then((res) => {
            res =
              "<h1>" +
              "Class announcement for " +
              topicName +
              "</h1>" +
              "<p>" +
              res +
              "</p>";
            res = res.replace(/\n/g, "<br />");
            setResult(result + res);
            setLoader(false);
            setmsg("");
            analytics.logEvent("workspace", {
              type: curr,
              topic: topicName,
              grade_level: selectedGrade
            });
          })
          .catch((error) => {
            setLoader(false);
            setmsg("")
            error = JSON.parse(error);
            if (error.error === "token has expired") {
              setError(true);
              setErrorMessage("Session expired. Please login again");
              setTimeout(() => {
                signOut();
              }, 2000);
              return;
            }
            setErrorMessage("Something went wrong");
            setError(true);
          });
        break;
      case "Email Template":
        fetch(EMAIL_TEMPLATE_URL, requestOptions)
          .then(async (response) => {
            const res = await response.text();
            if (!response.ok) throw res;
            return res;
          })
          .then((res) => {
            res =
              "<h1>" +
              "Email template for " +
              topicName +
              "</h1>" +
              "<p>" +
              res +
              "</p>";
            //handle next line as br tag
            res = res.replace(/\n/g, "<br />");
            setResult(result + res);
            setLoader(false);
            setmsg("");
            analytics.logEvent("workspace", {
              type: curr,
              topic: topicName,
              grade_level: selectedGrade
            });
          })
          .catch((error) => {
            setLoader(false);
            setmsg("")
            error = JSON.parse(error);
            if (error.error === "token has expired") {
              setError(true);
              setErrorMessage("Session expired. Please login again");
              setTimeout(() => {
                signOut();
              }, 2000);
              return;
            }
            setErrorMessage("Something went wrong");
            setError(true);
          });
        break;
      default:
        console.log("default");
    }
  }

  function handleClearContent() {
    setResult("");
  }

  function handleUpdateContent() {
    let token = localStorage.getItem("token");
    let selectedGrade = document.getElementById("grade").value;
    var myHeaders = new Headers();
    myHeaders.append("x-access-token", token);
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      topic: topicName,
      grade_level: selectedGrade,
      content: result,
    });
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(UPDATE_CONTENT_URL + id, requestOptions)
      .then(async (response) => {
        const res = await response.json();
        if (!response.ok) throw res;
        return res;
      })
      .then((res) => {
        setLoader(false);
        setSuccess(true);
      })
      .catch((error) => {
        setLoader(false);
        console.log("error", error);
        if (error.error === "token has expired") {
          setError(true);
          setErrorMessage("Session expired. Please login again");
          setTimeout(() => {
            signOut();
          }, 2000);
          return;
        }
        setErrorMessage("Something went wrong");
        setError(true);
      });
  }

  function handleSaveContent() {
    if (id) {
      handleUpdateContent();
      return;
    }
    let token = localStorage.getItem("token");
    let selectedGrade = document.getElementById("grade").value;
    var myHeaders = new Headers();
    myHeaders.append("x-access-token", token);
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      topic: topicName,
      grade_level: selectedGrade,
      content: result,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(SAVE_CONTENT_URL, requestOptions)
      .then(async (response) => {
        const res = await response.json();
        if (!response.ok) throw res;
        return res;
      })
      .then((res) => {
        //console.log(res);
        setSuccess(true);
      })
      .catch((error) => {
        console.log("error", error);
        if (error.error === "token has expired") {
          setError(true);
          setErrorMessage("Session expired. Please login again");
          setTimeout(() => {
            signOut();
          }, 2000);
          return;
        }
        setErrorMessage("Something went wrong");
        setError(true);
      });
  }

  const handleSuccessClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
  };

  const handleCopyText = () => {
    //copy text from quill text editor
    const quill = document.getElementById("editor");
    navigator.clipboard.writeText(editorRef.current.getEditor().getText());
  };

  return (
    <div className="w-full bg-[#17213A]">
      {success ? (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={success}
          autoHideDuration={3000}
          onClose={handleSuccessClose}
          key={"top" + "center"}
        >
          <Alert
            onClose={handleSuccessClose}
            // className="alert"
            variant="filled"
            severity="success"
          >
            Workspace content saved successfully!
          </Alert>
        </Snackbar>
      ) : null}
      {error ? (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={error}
          autoHideDuration={3000}
          onClose={() => setError(false)}
          key={"top" + "center"}
        >
          <Alert
            onClose={() => setError(false)}
            // className="alert"
            variant="filled"
            severity="error"
          >
            {errorMessage}
          </Alert>
        </Snackbar>
      ) : null}
      <div className="max-w-[99vw] m-auto px-4 py-16">
        <div className="grid lg:grid-cols-5">
          {/* left */}
          <div className=" top-0 col-span-3 lg:col-span-2 w-full bg-[#FCF6F5] rounded-tl-xl rounded-bl-xl p-4">
            <div className="h-auto">
              <div>
                <h2 className="text-xl font-bold">Workspace</h2> {/* Heading */}
                {/* Paragraph */}
                <hr className="border-gray-500 my-4" /> {/* Horizontal line */}
              </div>
              <div>
                <h3 className="text-lg font-bold">Enter Topic Name</h3>{" "}
                {/* Heading 1 */}
                <input
                  type="text"
                  value={topicName}
                  onChange={(e) => setTopicName(e.target.value)}
                  className="border border-gray-400 rounded-lg px-3 py-2 mt-2 w-full"
                  // placeholder="Enter topic name"
                />
              </div>
              <div>
                <h3 className="text-lg font-bold">Choose Grade Level</h3>{" "}
                {/* Heading 2 */}
                <select
                  id="grade"
                  className="border border-gray-400 rounded-lg px-3 py-2 mt-2 w-full"
                >
                  {/* Dropdown 2 */}
                  {Array.from(Array(12).keys()).map((k) => (
                    <option value={k + 1}>{k + 1}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="h-auto mt-10 grid grid-cols-1 md:grid-cols-2 gap-3">
              <WorkSpaceCards
                Svgbox={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    aria-hidden="true"
                    class="h-4 w-4 sm:h-5 sm:w-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M4 6h16M4 10h16M4 14h16M4 18h16"
                    ></path>
                  </svg>
                }
                handleClicked={handleClicked}
                title="Lesson Plan"
                content="Generate a lesson plan outline with an objective, possible strategies, and a range of options for assignments and activities. "
              />
              <WorkSpaceCards
                handleClicked={handleClicked}
                Svgbox={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    aria-hidden="true"
                    class="h-4 w-4 sm:h-5 sm:w-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M4 8h16M4 16h16"
                    ></path>
                  </svg>
                }
                title="Unit Plan"
                content="Generate an outline for an instruction unit"
              />
              <WorkSpaceCards
                handleClicked={handleClicked}
                Svgbox={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    aria-hidden="true"
                    class="h-4 w-4 sm:h-5 sm:w-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
                    ></path>
                  </svg>
                }
                title="Class Announcement"
                content="Develop a clear and concise statement to relay information to a class through an LMS or other system. "
              />
              <WorkSpaceCards
                handleClicked={handleClicked}
                Svgbox={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    aria-hidden="true"
                    class="h-4 w-4 sm:h-5 sm:w-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    ></path>
                  </svg>
                }
                title="Email Template"
                content="Produce a tactful outline of an email to guide your writing"
              />
            </div>
            {/* //Button to add to workspace */}
            <div className="flex justify-center mt-10">
              <button
                onClick={generateWorkspaceContent}
                className="bg-[#FFAF40] text-white rounded-lg px-4 md:px-8 py-2"
              >
                {loader ? (
                  <>
                  <div className="flex justify-center items-center h-full" style={{display:"flex",alignItems:"center"}}>
                    <Oval
                      height={50}
                      width={50}
                      color="#FFAF40"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#a1a1a1"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                    <p>Generating your content</p>
                  </div>
                  </>
        
                  
                ) : (
                  <span>Add to Workspace</span>
                )}
              </button>
            </div>
          </div>

          {/* right */}
          <div className="col-span-3 max-h-[90vh] w-full h-auto shadow-xl bg-[#FCF6F5]  rounded-tr-lg rounded-br-lg lg:p-4">
            <div className=" w-full flex justify-end items-center">
            <button
              onClick={handleCopyText}
              className="bg-[#FFAF40] self-end text-white rounded-lg px-4 md:px-8 py-2"
            >
              Copy
            </button>
            </div>
            <div className="w-auto h-[85%]">
              <div
                id="message"
                className="w-full border border-gray-400 rounded-lg px-3 py-2 mt-2 h-full"
              >
                <ReactQuill
                  id="editor"
                  ref={editorRef}
                  value={result}
                  onChange={handleEditorChange}
                  modules={{ toolbar: true }}
                  style={{ height: "92%", width: "100%" }}
                  formats={[
                    "header",
                    "font",
                    "size",
                    "bold",
                    "italic",
                    "underline",
                  ]}
                />
              </div>
            </div>
            <div className="flex md:flex-row md:space-y-0 md:space-x-5 py-2 px-5 md:px-12 space-x-2">
              <button
                onClick={handleSaveContent}
                className="bg-[#111827] text-white rounded-lg px-4 md:px-8 py-2"
              >
                Save
              </button>
              <button
                onClick={handleClearContent}
                className="bg-[#FF2B2B] text-white rounded-lg px-4 md:px-8 py-2"
              >
                Clear
              </button>
              <button
                onClick={handleCopyText}
                className="bg-[#FFAF40] text-white rounded-lg px-4 md:px-8 py-2"
              >
                Copy
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkSpace;
