import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GET_ALL_RESPONSE_URL } from "../constants/urls";
import { useDispatch } from "react-redux";
import { setSavedContent } from "../features/workspaceContent/contentSlice";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { Snackbar, Alert } from "@mui/material";

const SavedPage = () => {
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [error, setError] = useState(false);

  useEffect(() => {
    let token = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("x-access-token", token);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(GET_ALL_RESPONSE_URL, requestOptions)
      .then(async (response) => {
        const res = await response.json();
        if (!response.ok) throw res;
        return res;
      })
      .then((result) => {
        setData(result);
      })
      .catch((error) => {
        console.log("error", error);
        setError(true);
      });
  }, []);

  function addToIframe(result) {
    var iframe = document.querySelector(["[name=previewframe]"]);
    iframe.setAttribute("srcdoc", result);
    iframe.setAttribute("width", "100%");
    iframe.setAttribute("height", "100%");
    var iframeDocument = iframe.contentWindow.document;
    iframeDocument.body.innerHTML = result;
  }
  const [modalOpen, setModalOpen] = useState(false);
  const handleShowview = (item) => {
    setModalOpen(true);
    setSelectedItem(item);
  };

  useEffect(() => {
    if (modalOpen) addToIframe(selectedItem.content);
  }, [modalOpen]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleEdit = (item, index) => {
    dispatch(setSavedContent({ savedContent: item }));
    navigate(`/workspace/${index}`);
  };

  // const handleDelete = (id) => {
  //   const updatedData = data.filter((item) => item.id !== id);
  //   setData(updatedData);
  // };

  return (
    <div>
      {error ? (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={error}
          autoHideDuration={6000}
          onClose={() => setError(false)}
        >
          <Alert
            onClose={() => setError(false)}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            Error while fetching data
          </Alert>
        </Snackbar>
      ) : null}
      <div className="flex justify-center items-center h-screen bg-[#17213A]">
        <div className="w-[90%] md:w-[80%] lg:w-[80%] min-h-[40rem] bg-white rounded-md px-10 py-5">
          <div className="w-full text-4xl font-bold">My Content</div>
          <p className="text-left mt-4">
            Manage all your generated content in one place.
          </p>
          <div className="flex flex-col md:flex-row justify-center md:justify-end space-y-2 md:space-y-0 md:space-x-4 mt-4">
            <Link to="/workspace">
              <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                Add New
              </button>
            </Link>
            {/* <button
              className="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded"
              onClick={handleShowAll}
            >
              Show All
            </button> */}
          </div>
          <div className="mt-4">
            <table className="w-full">
              <thead>
                <tr className="text-lg">
                  <th className="px-4 py-2 ">Title</th>
                  <th className="px-4 py-2 hidden sm:table-cell">Grade</th>
                  <th className="px-4 py-2 hidden sm:table-cell">Created</th>
                  <th className="px-4 py-2 hidden sm:table-cell">Updated</th>
                  <th className="px-4 py-2 hidden sm:table-cell">Actions</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 text-sm">
                {data.map((item, index) => (
                  <tr key={item.id}>
                    <td className="px-4 py-2">
                      <div className="flex items-center justify-around">
                        {/* <div className="flex-shrink-0 h-8 w-8 bg-gray-200 rounded-full"></div> */}
                        <p className="">{item.topic}</p>
                      </div>
                    </td>
                    <td className="px-4 py-2 hidden sm:table-cell text-center">
                      {item.grade_level}
                    </td>
                    <td className="px-4 py-2 hidden sm:table-cell text-center">
                      {item.date_posted}
                    </td>
                    <td className="px-4 py-2 hidden sm:table-cell text-center">
                      {}
                    </td>
                    <td className="px-4 py-2 flex justify-around">
                      <a
                        className=" font-semibold text-blue-500 hover:text-blue-800  cursor-pointer"
                        onClick={() => handleShowview(item)}
                      >
                        <div className="p-1 rounded-md bg-slate-200 shadow-md">
                          <VisibilityOutlinedIcon />
                        </div>
                      </a>

                      <button
                        onClick={() => handleEdit(item, item.id)}
                        className="font-semibold text-green-500 hover:text-green-600 cursor-pointer"
                      >
                        <div className="p-1 rounded-md bg-slate-200 shadow-md">
                          <CreateOutlinedIcon />
                        </div>
                      </button>
                      <button
                        className="font-semibold text-red-500 hover:text-red-600  cursor-pointer"
                        // onClick={() => handleDelete(item.id)}
                      >
                        <div className="p-1 rounded-md bg-slate-200 shadow-md">
                          <DeleteOutlinedIcon />
                        </div>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {modalOpen && (
              <div className="fixed inset-0 flex items-center justify-center z-50 ">
                <div className="bg-gray-200 w-4/5 md:w-3/5 lg:w-1/2 rounded-lg shadow-xl min-h-[80vh]">
                  <div className="p-4 h-[75vh]">
                    <iframe name="previewframe" id="previewframe"></iframe>
                  </div>
                  <div className="flex justify-end p-4">
                    <button
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                      onClick={() => setModalOpen(false)}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SavedPage;
