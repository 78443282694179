import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    name:localStorage.getItem("name") ? localStorage.getItem("name") : "",
    email:localStorage.getItem("email") ? localStorage.getItem("email") : "",
    photo:localStorage.getItem("photo") ? localStorage.getItem("photo") : "",
}

const userSlice = createSlice({
    name:'user',
    initialState,
    reducers: {
        setUserLogin: (state,action)=>{
            state.name = action.payload.name;
            state.email = action.payload.email;
            state.photo = action.payload.photo;
        },
        setUserLogOut:(state)=>{
            state.name = null;
            state.email = null;
            state.photo=null;
        }
    }
})

export const {setUserLogin, setUserLogOut} = userSlice.actions;
export const selectUserName = (state)=>(state.user.name);
export const selectUserEmail = (state)=>state.user.email;
export const selectUserPhoto = (state)=>state.user.photo;
export default userSlice.reducer;