import React, { useEffect, useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { auth, provider, onAuthStateChanged, analytics } from "../firebase";
import {
  selectUserName,
  selectUserPhoto,
  setUserLogin,
  setUserLogOut,
} from "../features/users/userSlice";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LOGIN_URL, REGISTER_URL } from "../constants/urls";
import { dummyProfileImage } from "../constants/images";
import { Oval } from "react-loader-spinner";
import { Alert, Snackbar, Backdrop } from "@mui/material";




const SignUp = () => {
  const [loginData, setLoginData] = useState({ email: "", password: "", confpassword:""});
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function Login() {
    //console.log(loginData)
    if (loginData.password!==loginData.confpassword) {
      setErrorMessage("Password and Confirm Password does not match")
      return setError(true);
    }

    var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");
    // myHeaders.append("Access-Control-Allow-Origin", "*")
    myHeaders.append(
      "Authorization",
      "Basic " + btoa(loginData.email + ":" + loginData.password)
    );

    var raw = JSON.stringify({
      authorization: {
        username: loginData.email,
        password: loginData.password,
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    setLoading(true);
    fetch(LOGIN_URL, requestOptions)
      .then(async (response) => {
        const res = await response.json();
        if (!response.ok) throw res;
        return res;
      })
      .then((result) => {
        //console.log(result)

        localStorage.setItem("token", result.token);
        localStorage.setItem("email", loginData.email);
        localStorage.setItem("photo", dummyProfileImage);
        analytics.logEvent("login");
        dispatch(
          setUserLogin({
            name: loginData.email.split("@")[0],
            email: loginData.email,
            photo: dummyProfileImage,
          })
        );
        navigate("/workspace");
        setLoading(false);
        setSuccess(true);

      })
      .catch((error) => {
        console.log(error);
        //console.log("Ha bhai yaha")
        if (error.error === "Invalid username or password") {
          setErrorMessage("User with same email already exits");
          setLoading(false);
          setError(true);
          return;
        }
        //console.log(loginData)
        fetch(REGISTER_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // "Access-Control-Allow-Origin": "*",
          },
          
          body: JSON.stringify({
            username: loginData.email,
            password: loginData.password,
          }),
        })
          .then(async (res) => {
            const response = await res.json();
            if (!res.ok) throw response;
            return response;
          })
          .then((data) => {
            //console.log(data)
            Login();
          })
          .catch((err) => {
            console.log(err);
            setErrorMessage("Something went wrong");
            setLoading(false);
            setError(true);
          });
      });
  }

  function Login_auth(login) {
    //console.log(login)
    if (login.password!==login.confpassword) {
      setErrorMessage("Password and Confirm Password does not match")
      return setError(true);
    }

    var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");
    // myHeaders.append("Access-Control-Allow-Origin", "*")
    myHeaders.append(
      "Authorization",
      "Basic " + btoa(login.email + ":" + login.password)
    );

    var raw = JSON.stringify({
      authorization: {
        username: login.email,
        password: login.password,
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    setLoading(true);
    fetch(LOGIN_URL, requestOptions)
      .then(async (response) => {
        const res = await response.json();
        if (!response.ok) throw res;
        return res;
      })
      .then((result) => {
        //console.log(result)

        localStorage.setItem("token", result.token);
        localStorage.setItem("email", login.email);
        localStorage.setItem("photo", dummyProfileImage);
        analytics.logEvent("login");
        dispatch(
          setUserLogin({
            name: loginData.email.split("@")[0],
            email: loginData.email,
            photo: dummyProfileImage,
          })
        );
        navigate("/workspace");
        setLoading(false);
        setSuccess(true);

      })
      .catch((error) => {
        console.log(error);
        //console.log("Ha bhai yaha")
        if (error.error === "Invalid username or password") {
          setErrorMessage("User with same email already exits");
          setLoading(false);
          setError(true);
          return;
        }
        //console.log(loginData)
        fetch(REGISTER_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // "Access-Control-Allow-Origin": "*",
          },
          
          body: JSON.stringify({
            username: login.email,
            password: login.password,
          }),
        })
          .then(async (res) => {
            const response = await res.json();
            if (!res.ok) throw response;
            return response;
          })
          .then((data) => {
            //console.log(data)
            Login_auth(login);
          })
          .catch((err) => {
            console.log(err);
            setErrorMessage("Something went wrong");
            setLoading(false);
            setError(true);
          });
      });
  }

  const handleAuth = () => {
    auth
      .signInWithPopup(provider)
      .then((result) => {
        //console.log(result);
        let user = result.user;
        //console.log(user.email, user.uid);
        const login={
          email: user.email,
          password: user.uid,
          confpassword: user.uid
        }
        Login_auth(login)
      })
      .catch((error) => {
        console.log(error);
        alert(error.message);
      });
  };
  

  const handleFormSubmit = (e) => {
    e.preventDefault();
    //console.log(loginData);

    Login();
  };
  return (
    <>
      {success && (
        <Snackbar
          open={success}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={() => setSuccess(false)}
          key={"top" + "center"}
        >
          <Alert
            onClose={() => setSuccess(false)}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            Login Successful
          </Alert>
        </Snackbar>
      )}
      <div className=" flex flex-col md:flex-row justify-center items-center py-10 md:py-32 bg-[#17213A]">
        {loading && (
          <Backdrop open={loading}>
            <Oval color="#fff" height={50} width={50} />
          </Backdrop>
        )}
        {error && (
          <Snackbar
            open={error}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={() => setError(false)}
            key={"top" + "center"}
          >
            <Alert
              onClose={() => setError(false)}
              variant="filled"
              severity="error"
              sx={{ width: "100%" }}
            >
              {errorMessage}
            </Alert>
          </Snackbar>
        )}

        <div className="py-10 px-6 md:px-12 bg-gray-200 rounded-lg">
          <div className="flex items-center justify-center">
            <button
              onClick={handleAuth}
              className="bg-blue-500 hover:bg-blue-700 text-white font-medium py-3 px-20 rounded-lg w-full md:w-auto flex items-center"
            >
              <FcGoogle size={30} />{" "}
              <span className="ml-4">SignUp With Google</span>
            </button>
          </div>

          <div className="flex items-center my-4">
            <div className="flex-grow border-b border-gray-300"></div>
            <p className="text-center font-medium text-gray-600 mx-3 mb-0">
              Or
            </p>
            <div className="flex-grow border-b border-gray-300"></div>
          </div>
          <form onSubmit={handleFormSubmit}>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-gray-700 font-medium mb-2"
              >
                Email address
              </label>
              <input
                type="email"
                id="email"
                onChange={(e) =>
                  setLoginData({ ...loginData, email: e.target.value })
                }
                className="form-input w-full bg-gray-300 bg-opacity-70 rounded py-2 px-2 outline-none"
                // placeholder="Enter your email"
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="password"
                className="block text-gray-700 font-medium mb-2"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                onChange={(e) =>
                  setLoginData({ ...loginData, password: e.target.value })
                }
                className="form-input w-full bg-gray-300 bg-opacity-70 rounded py-2 px-2 outline-none"
                // placeholder="Enter your password"
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="Confirm password"
                className="block text-gray-700 font-medium mb-2"
              >
                Confirm Password
              </label>
              <input
                type="password"
                id="Confir password"
                onChange={(e) =>
                  setLoginData({ ...loginData, confpassword: e.target.value })
                }
                className="form-input w-full bg-gray-300 bg-opacity-70 rounded py-2 px-2 outline-none"
                // placeholder="Enter your password"
              />
            </div>

            {/* <div className="flex items-center justify-between mb-4">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="rememberMe"
                  className="form-checkbox mr-2"
                />
                <label htmlFor="rememberMe" className="text-gray-600">
                  Remember me
                </label>
              </div>
            </div> */}

            <div className="text-center md:text-left">
              <button
                type="submit"
                className="bg-[#17213A] hover:bg-[#111840] text-white font-medium py-3 px-20 rounded-lg w-full"
              >
                SignUp
              </button>
            </div>
            <div className="text-center mt-2">
              Already have an Account?
          <span className=" text-blue-500"><a href="/login"> LogIn</a></span>
          </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SignUp;
