import React, { useEffect, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import images from "../constants/images";
import { Link, useNavigate } from "react-router-dom";
import { auth, provider, onAuthStateChanged } from "../firebase";
import {
  selectUserName,
  selectUserPhoto,
  setUserLogin,
  setUserLogOut,
} from "../features/users/userSlice";
import { useSelector, useDispatch } from "react-redux";

// import {useNavigate} from 'react-router-dom'

const Navbar = () => {
  const loginData=useSelector((state)=>state.user);
  //console.log(loginData)


  const [toggleMenu, setToggleMenu] = React.useState(false);
  const [logoutView, setLogOutView] = useState(false);
  const navigate = useNavigate();
  const scroll = () => {
    let pageHeight = window.innerHeight;
    console.log(pageHeight);
    window.scrollTo(0, pageHeight + 100);
  };
  const dispatch = useDispatch();
  const UserName = useSelector(selectUserName);
  const UserPhoto = useSelector(selectUserPhoto);
  useEffect(() => {
    console.log(UserName, UserPhoto);
  }, [UserName, UserPhoto]);

  const signOut = () => {
    auth.signOut().then(() => {
      dispatch(setUserLogOut());
      navigate("/");
    });
    setLogOutView(false);
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    localStorage.removeItem("email");
    localStorage.removeItem("photo");
  };

  // useEffect(() => {
  //   auth.onAuthStateChanged(async (user) => {
  //     if (user) {
  //       dispatch(
  //         setUserLogin({
  //           name: user.displayName,
  //           email: user.email,
  //           photo: user.photoURL,
  //         })
  //       );
  //       navigate("/");
  //     }
  //   });
  // }, []);
  return (
    <nav className=" w-full flex flex-wrap items-center justify-between p-4 bg-[#17213A] text-white z-[100]  ">
      <div className="flex justify-start items-center lg:ml-10">
        <img src={images.logo} alt="app__logo" className="" style={{width:"58%"}}/>
      </div>
      <ul className="hidden sm:flex justify-center items-center space-x-10 tracking-widest">
        <li className="hover:text-green-400">
          <a href="/">Home</a>
        </li>
        {!loginData.photo?<ul className="hidden sm:flex justify-center items-center space-x-10 tracking-widest"><li onClick={scroll} className="hover:text-green-400">
          <a href="#features">Features</a>
        </li>
        <li className="hover:text-green-400">
          <a href="#tools">Tools</a>
        </li></ul>:
        <li onClick={scroll} className="hover:text-green-400">
        <a href="/workspace">Workspaces</a>
      </li>}
        {/*<li className="hover:text-green-400">
          <a href="#contact">Contact</a>
  </li>*/}
      </ul>
      <div className="flex justify-end items-center lg:mr-10">
        {UserPhoto ? (
          <li className=" list-none relative">
            <a className=" rounded-[50%]">
              <img
                onClick={() => setLogOutView(!logoutView)}
                className="w-16 h-16 rounded-[50%] cursor-pointer"
                referrerPolicy="no-referrer"
                src={UserPhoto}
              />
              <div
                style={logoutView ? { display: "block" } : { display: "none" }}
                id="logout"
                className="w-[160px] absolute top-18 left-[-50px] bg-white py-2 px-3 mt-2 rounded-sm text-black border-black border-solid border"
              >
                <a href="/saved">
                  <p className="cursor-pointer text-center">Saved Workspaces</p>
                </a>
                <a href="/workspace">
                  <p className="cursor-pointer text-center">Workspace</p>
                </a>
                <p onClick={signOut} className="cursor-pointer text-center">
                  Logout
                </p>
              </div>
            </a>
          </li>
        ) : (
          <button className="bg-green-500  hover:bg-green-700 py-2 px-5 rounded-lg hidden sm:block">
            <a href="/login" className="p-2 font-sans ">
              Sign In
            </a>
          </button>
        )}
      </div>
      <div className="sm:hidden">
        <GiHamburgerMenu fontSize={27} onClick={() => setToggleMenu(true)} />
        {toggleMenu && (
          <div className="fixed top-0 left-0 w-full h-screen transition duration-500 flex flex-col text-center items-center bg-[#17213A]">
            <AiOutlineClose
              fontSize={27}
              className="absolute top-4 right-4 cursor-pointer"
              onClick={() => setToggleMenu(false)}
            />
            <ul className="space-y-10 m-auto text-2xl tracking-widest">
              <li>
                <a href="#home" onClick={() => setToggleMenu(false)}>
                  Home
                </a>
              </li>
              <li>
                <a href="#features" onClick={() => setToggleMenu(false)}>
                  Features
                </a>
              </li>
              <li>
                <a href="#tools" onClick={() => setToggleMenu(false)}>
                  Tools
                </a>
              </li>
              <li>
                <a href="#contact" onClick={() => setToggleMenu(false)}>
                  Contact
                </a>
              </li>
              {UserPhoto ? (
                <li>
                  <a className=" w-20 h-20 rounded-[50%]">
                    <img src={UserPhoto} />
                  </a>
                </li>
              ) : (
                <li>
                  <a
                    className="bg-green-500 py-1 px-3 rounded-lg"
                    href="#contact"
                    onClick={() => setToggleMenu(false)}
                  >
                    Sign In
                  </a>
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};
export default Navbar;
