import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import "./tailwindCSS/Tailwind_Output.css";
import WorkSpace from "./Components/WorkSpace";
import Banner from "./Components/Banner";
import Contact from "./Components/Contact";
import Features from "./Components/Features";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import Lms from "./Components/Lms";
import Main from "./Components/Main";
import SavedPage from "./Components/SavedPage";
import SignUp from "./Components/SignUp";
import LogIn from "./Components/Login"
import Review from "./utils/reviews";
import Tools from "./Components/Tools";
import { useSelector } from "react-redux";

function App() {
  const loginData = useSelector((state) => state.user);
  console.log(loginData)
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route
          key="home"
          exact path="/"
          element={ 
          <div>
            <Main />
            <Features />
            <Tools />
            <Lms />
            <Review />
            <Banner />
          </div> 
            
          }
        />
        <Route key="contact" path="/contact" element={<Contact />} />
        <Route key="signup" path="/signup" element={<SignUp />} />
        <Route key="login" path="/login" element={<LogIn />} />
        <Route path="/workspace" element={loginData.photo ? <WorkSpace /> : <Navigate to="/signup" />} />
        <Route path="/workspace/:id" element={loginData.photo? <WorkSpace /> : <Navigate to="/signup" />} />
        <Route path="/saved" element={loginData.photo? <SavedPage /> : <Navigate to="/signup" />} />
        <Route path="*" element={<Navigate to="/signup" />} />


      </Routes>
      <Footer />
    </BrowserRouter>
  );
}
const RedirectToLogin = () => {
  // Use the <Navigate> component to programmatically redirect to the /about route
  return <Navigate to="/login" />;
};

export default App;
