import React, { useEffect, useState } from "react";
import { AiFillEdit } from "react-icons/ai";

const WorkSpaceCards = ({ title, content, handleClicked, Svgbox }) => {
  return (
    <div id={title} className="bg-white p-4 relative">
      <div
        onClick={() => handleClicked(title)}
        className="absolute top-0 left-0 bottom-0 right-0 cursor-pointer"
      ></div>
      <div className="flex justify-between items-center mb-2">
        <div className="p-2 bg-[#FCF6F5]">{Svgbox}</div>
        <h1 className="font-bold text-lg text-right">{title}</h1>
        <div>{/* <AiFillEdit size={25} /> */}</div>
      </div>
      <p className="text-sm text-gray-500">{content}</p>
    </div>
  );
};

export default WorkSpaceCards;
