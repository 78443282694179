import React from "react";
import lms from "../Assets/lms.svg";
import { Link } from "react-router-dom";

const Lms = () => {
  return (
    <div
      id="contact"
      className="bg-[#181846] h-[700px] w-full flex flex-col md:flex-row items-center justify-center space-y-0 md:space-y-0"
    >
      <div className="max-w-[500px] md:mr-10 mb-28 px-5 md:px-0 ">
        <h1 className=" text-2xl md:text-6xl text-green-500 font-medium mb-4">
          Want To be more productive?
        </h1>
        <p className="text-white max-w-lg md:max-w-xl text-sm md:text-md">
          We provide teachers with a range of tools that help them be more
          creative, develop their skills, and plan lessons that suit their
          students. Our platform also makes it easier to find and access
          educational resources in a new and efficient way.
        </p>
        <p className="text-white max-w-lg md:max-w-xl text-sm md:text-md mt-2">
          Reach out to our sales team for LMS or CMS integration.
        </p>
        <Link to="https://edjobster.com/contact/" target="_blank" rel="noopener noreferrer">
          <button className="text-white font-bold bg-green-500 hover:bg-green-600 mt-6 text-center py-2 px-6 ">
            Contact Us
          </button>
        </Link>
      </div>
      <div className="h-fit w-full md:w-[800px] mt-20 px-5 md:px-0">
        <img src={lms} alt="header_img" />
      </div>
    </div>
  );
};

export default Lms;
