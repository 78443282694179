const BASE_URL = process.env.REACT_APP_BASE_URL;

export const REGISTER_URL = `${BASE_URL}/auth/register`;
export const LOGIN_URL = `${BASE_URL}/auth/login`;
export const LESSON_PLAN_URL = `${BASE_URL}/main/lesson-plan`;
export const UNIT_PLAN_URL = `${BASE_URL}/main/unit-plan`;
export const CLASS_ANNOUNCEMENT_URL = `${BASE_URL}/main/class-announcement`;
export const EMAIL_TEMPLATE_URL = `${BASE_URL}/main/email-template`;
export const SAVE_CONTENT_URL = `${BASE_URL}/main/save-response`;
export const GET_ALL_RESPONSE_URL = `${BASE_URL}/main/responses`;
export const UPDATE_CONTENT_URL = `${BASE_URL}/main/update-response/`;
export const CONTACT_US_URL = `${BASE_URL}/main/contact-us`;