import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    savedContent:{}
}

const contentSlice = createSlice({
    name:'content',
    initialState,
    reducers: {
        setSavedContent: (state,action)=>{
            state.savedContent = action.payload.savedContent;
        },
    }
})

export const {setSavedContent} = contentSlice.actions;
export const selectSavedContent = (state)=>(state.content.savedContent);
export default contentSlice.reducer;