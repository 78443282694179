import React from "react";
import tool1 from "../Assets/tool1.svg";
import tool2 from "../Assets/tool2.svg";

const Tools = () => {
  return (
    <section className="bg-[#FCF6F5] py-8" id="tools">
      <div className="container max-w-5xl mx-auto m-8">
        <h1 className="w-full my-2 text-5xl font-bold leading-tight text-center text-primary">
          Tools Provided
        </h1>
        <div className="flex flex-wrap">
          <div className="w-5/6 sm:w-1/2 p-6 mt-20">
            <h3 className="text-3xl text-green-500 font-bold leading-none mb-3">
              lessop.ai
            </h3>
            <p className="text-gray-600">
              Ai tool to generate lesson plans and classroom ideas to be a
              productive and smart teacher
            </p>
          </div>
          <div className="w-full sm:w-1/2 p-6">
            <img src={tool1} alt="" />
          </div>
        </div>
        <div className="flex flex-wrap flex-col-reverse sm:flex-row">
          <div className="w-full sm:w-1/2 p-6">
            <img src={tool2} alt="" />
          </div>
          <div className="w-full sm:w-1/2 p-6 m-auto ">
            <div className="align-middle text-center ">
              <h3 className="text-3xl  text-green-500  font-bold leading-none mb-3">
                Tools Coming Soon..
              </h3>
              <div className="flex flex-col items-start w-full">
                <p className="text-3xl font-semibold">AI Teaching Assitant</p>
                <p className="text-lg my-4 text-left">
                  Smart AI powered Chatbot to help teachers with instant
                  solutions for educators
                </p>
                <p className="text-3xl font-semibold">Hyde.AI</p>
                <p className="text-lg my-4 text-left">
                  Save time on hiring by generating attractive job descriptions
                  with generative AI tool
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Tools;
